import { initClickableArea, tabHighlight, tabHighlightRemove } from "./components/global_accessibility";

export default {
	init() {
		// JavaScript to be fired on all pages
			// Accessibility: Handle tab highlighting
		//---------------------------------------------------------------------------
		$("body").on("keyup", "a, .btn, button, input, textarea, select, .clickable-area", function (e) {
			tabHighlight(e);
		});

		$("body").on("blur", ".tabbed", function (e) {
			tabHighlightRemove(e);
		});

		// Accessibility: Initiate Cickabel areas
		//---------------------------------------------------------------------------
		initClickableArea();
		
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
